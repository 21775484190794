'use client';

import type { AutocompleteLegacyProps } from '@i2e/components';
import { AutocompleteLegacy, Label, cn } from '@i2e/components';
import { ErrorIcon } from '@in2event/icons';
import { Slot } from '@radix-ui/react-slot';
import type { ComponentPropsWithoutRef, ElementRef, ForwardedRef, HTMLAttributes } from 'react';
import { createContext, forwardRef, useContext, useId } from 'react';
import type { ControllerProps, FieldPath, FieldValues, ValidationRule } from 'react-hook-form';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

const Form = FormProvider;

interface FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
    name: TName;
    required?: string | ValidationRule<boolean> | undefined;
}

const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: ControllerProps<TFieldValues, TName>) => {
    return (
        <FormFieldContext.Provider value={{ name: props.name, required: props.rules?.required }}>
            <Controller {...props} />
        </FormFieldContext.Provider>
    );
};

const useFrom = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>() => {
    const formContext = useFormContext<TFieldValues, TName>();

    if (!formContext) {
        throw new Error('useFrom should be used within <Form>');
    }

    return formContext;
};

const useFormField = () => {
    const fieldContext = useContext(FormFieldContext);
    const itemContext = useContext(FormItemContext);
    const { getFieldState, formState } = useFormContext();

    const fieldState = getFieldState(fieldContext.name, formState);

    if (!fieldContext) {
        throw new Error('useFormField should be used within <FormField>');
    }

    const { id } = itemContext;

    return {
        id,
        name: fieldContext.name,
        required: fieldContext.required,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState,
    };
};

interface FormItemContextValue {
    id: string;
}

const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const id = useId();

        return (
            <FormItemContext.Provider value={{ id }}>
                <div ref={ref} className={cn('space-y-1', className)} {...props} />
            </FormItemContext.Provider>
        );
    },
);
FormItem.displayName = 'FormItem';

const FormLabel = forwardRef<ElementRef<typeof Label>, ComponentPropsWithoutRef<typeof Label>>(
    ({ children, ...props }, ref) => {
        const { formItemId, required } = useFormField();

        return (
            <Label ref={ref} htmlFor={formItemId} {...props}>
                {children}
                {required ? <span> *</span> : null}
            </Label>
        );
    },
);
FormLabel.displayName = 'FormLabel';

const FormControl = forwardRef<ElementRef<typeof Slot>, ComponentPropsWithoutRef<typeof Slot>>(
    ({ className, ...props }, ref) => {
        const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

        return (
            <Slot
                ref={ref}
                id={formItemId}
                aria-describedby={
                    !error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`
                }
                aria-invalid={Boolean(error)}
                className={cn({ 'border-destructive-600 border-2': error }, className)}
                {...props}
            />
        );
    },
);
FormControl.displayName = 'FormControl';

const FormControlAutocomplete = forwardRef(FormControlAutocompleteInner) as <T>(
    props: AutocompleteLegacyProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof FormControlAutocompleteInner>;

function FormControlAutocompleteInner<T>(
    { inputProps: { className, ...inputProps }, ...props }: AutocompleteLegacyProps<T>,
    ref: ForwardedRef<HTMLInputElement>,
) {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

    return (
        <AutocompleteLegacy
            ref={ref}
            inputProps={{
                id: formItemId,
                'aria-label': formItemId,
                'aria-describedby': !error
                    ? formDescriptionId
                    : `${formDescriptionId} ${formMessageId}`,
                'aria-invalid': Boolean(error),
                className: cn({ 'border-destructive-600 border-2': error }, className),
                ...inputProps,
            }}
            {...props}
        />
    );
}

const FormDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => {
        const { formDescriptionId } = useFormField();

        return (
            <p
                ref={ref}
                id={formDescriptionId}
                className={cn('text-xs font-normal text-neutral-50', className)}
                {...props}
            />
        );
    },
);
FormDescription.displayName = 'FormDescription';

const FormMessage = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement> & {
        t?: (key: string) => string;
    }
>(({ className, children, t, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? (t?.(String(error.message)) ?? String(error.message)) : children;
    const hasError = Boolean(error);

    if (!body) {
        return null;
    }

    return (
        <div
            ref={ref}
            id={formMessageId}
            className={cn('flex items-start pt-1', className)}
            {...props}
        >
            {hasError ? (
                <ErrorIcon
                    className={cn('mr-1 size-4 shrink-0 fill-destructive-600')}
                />
            ) : null}
            <span
                className={cn('grow break-words text-xs font-medium text-neutral-50', {
                    'text-destructive-600': hasError,
                })}
            >
                {body}
            </span>
        </div>
    );
});
FormMessage.displayName = 'FormMessage';

export {
    useFrom,
    useFormField,
    Form,
    FormItem,
    FormLabel,
    FormControl,
    FormDescription,
    FormMessage,
    FormField,
    FormControlAutocomplete,
};
